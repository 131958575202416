// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pilates-js": () => import("./../../../src/pages/pilates.js" /* webpackChunkName: "component---src-pages-pilates-js" */),
  "component---src-pages-praktisch-js": () => import("./../../../src/pages/praktisch.js" /* webpackChunkName: "component---src-pages-praktisch-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-specialisaties-js": () => import("./../../../src/pages/specialisaties.js" /* webpackChunkName: "component---src-pages-specialisaties-js" */),
  "component---src-pages-wiezijnwe-js": () => import("./../../../src/pages/wiezijnwe.js" /* webpackChunkName: "component---src-pages-wiezijnwe-js" */),
  "component---src-templates-behandeling-js": () => import("./../../../src/templates/behandeling.js" /* webpackChunkName: "component---src-templates-behandeling-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

